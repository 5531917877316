import { uploadImage } from '@/api/attachments'
import { createProject } from '@/api/projects'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQueryClient } from '@tanstack/react-query'
import { type LucideIcon, Upload } from 'lucide-react'
import { type ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import DefaultProjectIcon from '@/assets/icons/default-project-icon.svg?react'
import { handleError } from '@/lib/HandleErrors'
import { cn } from '@/lib/utils'

interface NewAppForm {
    name: string
    description: string
    projectIconId: string
}

export const CreateNewProject = ({
    variant,
    onSuccess,
    size,
    Icon,
    customSizeClassName,
}: {
    variant:
        | 'default'
        | 'destructive'
        | 'outline'
        | 'secondary'
        | 'ghost'
        | 'link'
        | null
        | undefined
    size?: 'default' | 'sm' | 'lg' | 'icon' | 'custom'
    customSizeClassName?: string
    Icon?: LucideIcon
    onSuccess?: () => void
}) => {
    const queryClient = useQueryClient()
    const [openCreate, setOpenCreate] = useState(false)
    const appForm = useForm<NewAppForm>()
    const { t } = useTranslation()
    const [fileToUpload, setFileToUpload] = useState<{
        file: File
        previewUrl: string
    }>()

    const handleAddIcon = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files)
            const newItems = filesArray.map((file) => ({
                file,
                previewUrl: URL.createObjectURL(file),
            }))
            setFileToUpload(newItems[0])
            const res = await uploadImage({ file: newItems[0].file })
            appForm.setValue('projectIconId', res.id)
        }
    }

    const handleCreateProject = async (data: NewAppForm) => {
        try {
            //@ts-ignore
            await createProject({
                ...data,
                pipelines: [],
                models: [],
                dataSources: [],
                prompts: [],
                apiKeys: [],
                memories: [],
                credentials: [],
            })

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PROJECTS] })
            setOpenCreate(false)
            onSuccess && onSuccess()
        } catch (error) {
            handleError(error, 'Project')
        }
    }
    return (
        <div className={cn(customSizeClassName && customSizeClassName, '')}>
            <Button
                type="button"
                size={size === 'custom' ? undefined : size}
                className={cn(customSizeClassName && customSizeClassName, '')}
                onClick={(e) => {
                    e.stopPropagation()
                    setOpenCreate(true)
                }}
                variant={variant}
            >
                {Icon ? <Icon className="h-4 w-4" /> : t('project_create')}
            </Button>
            <Dialog open={openCreate} onOpenChange={setOpenCreate}>
                <form onSubmit={appForm.handleSubmit(handleCreateProject)}>
                    <DialogContent className="max-w-modal-sm max-h-modal">
                        <DialogHeader>
                            <DialogTitle>Create new project</DialogTitle>
                        </DialogHeader>
                        <div className="grid gap-4 py-4 w-full">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <div className="flex flex-col gap-2 min-w-72 w-full">
                                    <Label htmlFor="name" className="text-left">
                                        Name:
                                    </Label>
                                    <Input
                                        id="name"
                                        type="text"
                                        className="col-span-3"
                                        {...appForm.register('name', { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-72">
                                <Label htmlFor="description" className="text-left">
                                    Description:
                                </Label>
                                <textarea
                                    id="description"
                                    className="col-span-3 bg-background border border-gray-300 py-1 px-3 rounded-md"
                                    {...appForm.register('description')}
                                />
                            </div>
                            <div>
                                <h3 className="text-lg">Icon: </h3>
                                <div className="flex flex-row gap-2 items-center">
                                    {fileToUpload?.previewUrl ? (
                                        <img
                                            src={fileToUpload?.previewUrl}
                                            alt={t('imgAlt.projectIcon')}
                                            className=" w-16 h-16 rounded-md object-cover"
                                        />
                                    ) : (
                                        <DefaultProjectIcon className=" w-16 h-16 rounded-md " />
                                    )}
                                    <input
                                        type="file"
                                        id="appIconUpload"
                                        style={{ display: 'none' }}
                                        onChange={handleAddIcon}
                                    />

                                    <Button
                                        variant="ghost"
                                        onClick={() =>
                                            document?.getElementById('appIconUpload')?.click()
                                        }
                                        type="button"
                                    >
                                        <Upload />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                type="submit"
                                onClick={appForm.handleSubmit(handleCreateProject)}
                            >
                                Create
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}
