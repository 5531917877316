import type { routeTree } from '@/routeTree.gen'
import type { RoutePaths } from '@tanstack/react-router'
import {
    ChartColumn,
    LibraryBig,
    SquareActivity,
    Settings,
    LayoutPanelTop,
    type LucideIcon,
    // BadgeCheck,
    Plug,
} from 'lucide-react'

export type Submenu = {
    path: RoutePaths<typeof routeTree>
    params?: Record<string, string>
    label: string
    id?: string
    disabled?: boolean
}

export type Menu = {
    path?: RoutePaths<typeof routeTree>
    params?: Record<string, string>
    label: string
    icon: LucideIcon
    submenus: Submenu[]
    active: boolean
}

export type MenuGroup = {
    groupLabel: string
    menus: Menu[]
}

export const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
export const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'

export function getMenuList(path: string): MenuGroup[] {
    return [
        {
            groupLabel: '',
            menus: [
                {
                    path: '/overview',
                    active: path.includes('/overview'),
                    label: 'Insights',
                    icon: ChartColumn,
                    submenus: [],
                },
                {
                    label: 'Projects',
                    icon: LayoutPanelTop,
                    active:
                        !path.includes('/library') &&
                        (path.includes('/projects') ||
                            path.includes('/pipelines') ||
                            path.includes('/models') ||
                            path.includes('/datasets') ||
                            path.includes('/prompts') ||
                            path.includes('/memory') ||
                            path.includes('/tools')),
                    submenus: [],
                },
                {
                    label: 'Libraries',
                    icon: LibraryBig,
                    active: path.includes('/library'),
                    submenus: [
                        // {
                        //     path: '/',
                        //     label: 'Code',
                        //     disabled: true,
                        // },
                        {
                            path: '/library/models',
                            label: 'Models',
                        },
                        {
                            path: '/library/prompts',
                            label: 'Prompts',
                        },
                        // {
                        //     path: '/',
                        //     label: 'Templates',
                        //     disabled: true,
                        // },
                    ],
                },
                {
                    label: 'Integrations',
                    icon: Plug,
                    active: path.includes('/integrations') || path === '/tools',
                    submenus: [
                        {
                            path: '/integrations/datasources',
                            label: 'Connectors',
                        },
                        {
                            path: '/integrations/tools',
                            label: 'Tools',
                        },
                    ],
                },
                {
                    label: 'Feeds',
                    icon: SquareActivity,
                    active: path.includes('/feed') || path.includes('/consumption'),
                    submenus: [
                        {
                            path: '/feed/ingestion',
                            label: 'Data Ingestion',
                        },
                        {
                            path: '/feed/processing',
                            label: 'Data Processing',
                        },
                        {
                            path: '/feed/gateway',
                            label: 'Gateway Monitoring',
                        },
                        {
                            path: '/feed/execution',
                            label: 'Pipeline Executions',
                        },
                        {
                            path: '/feed/consumption',
                            label: 'Token Consumption',
                        },
                    ],
                },
                // {
                //     label: 'Policies',
                //     icon: BadgeCheck,
                //     active: false,
                //     submenus: [
                //         {
                //             path: '/',
                //             label: 'Governance',
                //             disabled: true,
                //         },
                //         {
                //             path: '/',
                //             label: 'Intelligent Routing',
                //             disabled: true,
                //         },
                //         {
                //             path: '/',
                //             label: 'Mobile Apps',
                //             disabled: true,
                //         },
                //         {
                //             path: '/',
                //             label: 'Security & DLP',
                //             disabled: true,
                //         },

                //         {
                //             path: '/',
                //             label: 'Spend Management',
                //             disabled: true,
                //         },
                //     ],
                // },
                {
                    label: 'Settings',
                    icon: Settings,
                    active: path.includes('/settings'),
                    path: '/settings/users',
                    submenus: [],
                },
            ],
        },
    ]
}

export function getAccordionLabels(): string[] {
    return getMenuList('').reduce((acc, group) => {
        acc.push(...group.menus.map((menu) => menu.label))
        return acc
    }, [] as string[])
}
