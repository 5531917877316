import { DefaultDataTable } from '@/components/DefaultDataTable'
import DragAndDropLegal from '@/components/legal/documents/dragAndDropLegal'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader } from '@/components/ui/dialog'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { toast } from '@/components/ui/use-toast'
import { TabsContent } from '@radix-ui/react-tabs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { t } from 'i18next'
import {
    AlertCircle,
    CheckCircle,
    Clock,
    Loader2,
    MoreHorizontal,
    RotateCcw,
    Trash,
} from 'lucide-react'
import millify from 'millify'
import { useState } from 'react'

import {
    CreateDataSourceFile,
    type DataSourceFileOverview,
    DeleteDataSourceFile,
    GetDatasetById,
    GetDatasetFilesById,
    ProcessingStatus,
    UpdateDataSourceFile,
} from '@/api/datasets'
import { QueryKeys } from '@/constants/QueryKeys'

import File from '@/assets/icons/file.svg?react'
import { useAuth } from '@/hooks/use-auth'
import type { ColumnDef } from '@tanstack/react-table'

export const Route = createFileRoute('/_legalLayout/$projectId/legal/$datasourceId')({
    component: DocumentModal,
})

function DocumentModal() {
    const navigate = useNavigate()
    const params = Route.useParams()
    const queryClient = useQueryClient()
    const { user, isLoading } = useAuth()
    const [files, setFiles] = useState<File[]>([])
    const [activeTab, setActiveTab] = useState('view')

    const dataSourceQuery = useQuery({
        queryKey: ['current-data-source', params.datasourceId],
        queryFn: () => GetDatasetById(params.datasourceId),
        enabled: !isLoading && !!user?.id,
    })

    const fileListQuery = useQuery({
        queryKey: [QueryKeys.FILE_LIST, params.datasourceId, user?.id ?? ''],
        //@ts-ignore
        queryFn: () => GetDatasetFilesById(params.datasourceId, { userId: user?.id }),
        enabled: !isLoading && !!user?.id,
    })

    const createDataSetFileMutation = useMutation({
        mutationFn: (args: { id: string; file: File }) =>
            //@ts-ignore
            CreateDataSourceFile(args.id, args.file, { userId: user?.id }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FILE_LIST, params.datasourceId],
            })
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS],
            })
            toast({
                title: t('success_title'),
                description: 'Successfully uploaded file',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const updateDataSetFileMutation = useMutation({
        mutationFn: (args: { dataSourceId: string; fileId: string; file: File }) =>
            UpdateDataSourceFile(args.dataSourceId, args.fileId, args.file, {
                //@ts-ignore
                userId: user?.id,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FILE_LIST, params.datasourceId],
            })
            toast({
                title: t('success_title'),
                description: t('file_update_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const deleteFileMutation = useMutation({
        mutationFn: (args: { id: string; fileId: string }) =>
            DeleteDataSourceFile(args.id, args.fileId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FILE_LIST, params.datasourceId],
            })
            toast({
                title: t('success_title'),
                description: t('file_delete_success'),
            })
        },
        onError: (error) => {
            console.error(error)
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleUpload = async (file: File, action?: 'update' | 'addBoth') => {
        if (action === 'update') {
            const existingFile = fileListQuery.data?.find((f) => f.name === file.name)
            if (existingFile) {
                await updateDataSetFileMutation.mutateAsync({
                    dataSourceId: params.datasourceId,
                    fileId: existingFile.fileId ?? '',
                    file: file,
                })
            }
        } else {
            // For new files or 'addBoth' action
            let fileName = file.name
            if (action === 'addBoth') {
                // Append timestamp to filename for 'addBoth' action
                const timestamp = Date.now()
                const nameParts = file.name.split('.')
                const extension = nameParts.pop()
                fileName = `${nameParts.join('.')}_${timestamp}.${extension}`
            }
            const newFile =
                //@ts-ignore
                action === 'addBoth' ? new File([file], fileName, { type: file.type }) : file
            await createDataSetFileMutation.mutateAsync({ id: params.datasourceId, file: newFile })
        }
    }

    const handleDeleteFile = async (fileId: string) => {
        if (!fileId) return
        await deleteFileMutation.mutateAsync({ id: params.datasourceId, fileId })
    }

    const statusIcons = {
        [ProcessingStatus.pending]: Clock,
        [ProcessingStatus.processing]: Loader2,
        [ProcessingStatus.processed]: CheckCircle,
        [ProcessingStatus.failed]: AlertCircle,
    }

    const statusColors = {
        [ProcessingStatus.pending]: 'text-yellow-500',
        [ProcessingStatus.processing]: 'text-blue-500',
        [ProcessingStatus.processed]: 'text-green-500',
        [ProcessingStatus.failed]: 'text-red-500',
    }

    const columns: ColumnDef<DataSourceFileOverview, unknown>[] = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }) => (
                <div className="flex flex-row gap-4 items-center">
                    <File className="w-6 h-6" />
                    <p>{row.original.name}</p>
                </div>
            ),
        },
        {
            accessorKey: 'mimeType',
            header: 'Type',
        },
        {
            accessorKey: 'size',
            header: 'Size',
            cell: ({ row }) => (
                <p>
                    {millify(row.original.size, {
                        units: ['B', 'KB', 'MB', 'GB', 'TB'],
                        space: true,
                    })}
                </p>
            ),
        },
        {
            accessorKey: 'lastUpdated',
            header: 'Last Modified',
            cell: ({ row }) => <p>{new Date(row.original.lastUpdated).toLocaleString()}</p>,
        },
        {
            accessorKey: 'processingStatus',
            header: 'Status',
            cell: ({ row }) => {
                const status = row.original.processingStatus as ProcessingStatus
                const IconComponent = statusIcons[status]
                return (
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div className="flex items-center gap-2">
                                    <IconComponent className={`h-5 w-5 ${statusColors[status]}`} />
                                    <span>{status}</span>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{status}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                )
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            header: 'Actions',
            cell: ({ row }) => {
                const fileIdExists = !row.original.fileId
                return (
                    <div className="justify-end flex pr-5">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0 hover:text-black">
                                    <MoreHorizontal className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuLabel className="flex gap-1">
                                    {t('actions')}
                                </DropdownMenuLabel>
                                <DropdownMenuItem
                                    className="flex gap-1 text-yellow-500"
                                    disabled={fileIdExists}
                                >
                                    <RotateCcw className="w-4 h-4" />
                                    Reprocess
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    className="flex gap-1 text-red-500"
                                    onClick={() =>
                                        !fileIdExists &&
                                        handleDeleteFile(row?.original?.fileId ?? '')
                                    }
                                    disabled={fileIdExists}
                                >
                                    <Trash className="h-4 w-4" />
                                    {t('delete')}
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        },
    ]

    return (
        <Dialog open onOpenChange={() => navigate({ to: '../' })}>
            <DialogContent className="max-w-modal-lg h-[730px] max-h-modal rounded-2xl flex flex-col w-[75vw]">
                <DialogHeader className="text-2xl font-gilroy-medium font-normal leading-10 h-[40px] p-0 m-0">
                    {dataSourceQuery.data?.name ?? 'Documents'}
                </DialogHeader>
                <DialogDescription>View, upload, and manage your documents</DialogDescription>
                <Tabs
                    value={activeTab}
                    onValueChange={setActiveTab}
                    className="flex-grow flex flex-col"
                >
                    <TabsList className="justify-center align-center items-center bg-transparent my-3">
                        <TabsTrigger
                            value="view"
                            className="data-[state=active]:text-primary-legal data-[state=active]:bg-background p-2 rounded-xl"
                        >
                            View
                        </TabsTrigger>
                        <TabsTrigger
                            value="upload"
                            className="data-[state=active]:text-primary-legal data-[state=active]:bg-background p-2 rounded-xl"
                        >
                            Upload
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="view" className="flex-grow flex flex-col">
                        <Tabs
                            defaultValue="cards"
                            className="flex-grow flex flex-col h-64 overflow-y-auto"
                        >
                            <DefaultDataTable columns={columns} data={fileListQuery.data ?? []} />
                        </Tabs>
                    </TabsContent>
                    <TabsContent value="upload" className="flex-grow">
                        <DragAndDropLegal
                            files={files}
                            setFiles={setFiles}
                            handleUpload={handleUpload}
                            onUploadComplete={() => {
                                setFiles([])
                                queryClient.invalidateQueries({
                                    queryKey: [QueryKeys.FILE_LIST, params.datasourceId],
                                })
                                setActiveTab('view')
                            }}
                            existingFiles={fileListQuery.data ?? []}
                        />
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog>
    )
}
