import type { Connector } from '@/api/connectors.tsx'
import { ConnectorCard } from '@/components/featuredConnectors/ConnectorCard'
import SelectProjectForm from '@/components/modelLibrary/selectProjectForm.tsx'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { useRouter } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import MegaSearch from '@/components/megaSearch/MegaSearch.tsx'
import { useQuery } from '@tanstack/react-query'
import { FeatureFlag, getFeatureFlag } from '@/api/featureFlag.ts'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import { LayoutGrid, List } from 'lucide-react'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { ColumnDef } from '@tanstack/react-table'
import PageBanner from '@/components/pageBanner/pageBanner.tsx'
import DataConnectorBanner from '@/assets/data-connector.png'

interface ConnectorsLibraryProps {
    connectors: Connector[]
    projectId?: string
}

const enabledConnectors = ['AzureBlob', 'CloudKit', 'FileUpload']

export function ConnectorsLibrary({ connectors, projectId }: ConnectorsLibraryProps) {
    const storageKey = 'data-connector-banner'
    const router = useRouter()
    const [search, setSearch] = useState('')
    const [filteredConnectors, setFilteredConnectors] = useState<Connector[]>(connectors)
    const [addToProjectOpen, setAddToProjectOpen] = useState(false)
    const [selectedConnectorId, setSelectedConnectorId] = useState('')
    const [bannerVisible, setBannerVisible] = useState<boolean>(
        localStorage.getItem(storageKey) != 'false'
    )

    useEffect(() => {
        if (search !== '') {
            setFilteredConnectors(() =>
                connectors.filter(
                    (connector) =>
                        connector.name.toLowerCase().includes(search.toLowerCase()) ||
                        connector.classification.toLowerCase().includes(search.toLowerCase())
                )
            )
        } else {
            setFilteredConnectors(connectors)
        }
    }, [search, connectors])

    const onCardClick = (connectorId: string) => {
        if (!enabledConnectors.includes(connectorId) && !enableAllConnectors) return
        if (projectId) {
            router.navigate({
                to: '/$projectId/datasets/new',
                params: {
                    projectId,
                },
                search: {
                    connectorId: connectorId,
                },
            })
            return
        }
        setSelectedConnectorId(connectorId)
        setAddToProjectOpen(true)
    }

    const onAddToProject = (projectId: string) => {
        router.navigate({
            to: '/$projectId/datasets/new',
            params: {
                projectId,
            },
            search: {
                connectorId: selectedConnectorId,
            },
        })
    }

    const { data: enableAllConnectors } = useQuery({
        queryKey: [FeatureFlag.EnableAllConnectorsFeatureFlag],
        queryFn: () => getFeatureFlag(FeatureFlag.EnableAllConnectorsFeatureFlag),
    })

    const onBannerButtonClick = () => {
        localStorage.setItem(storageKey, 'false')
        setBannerVisible(false)
    }

    const columns: ColumnDef<Connector>[] = [
        {
            id: 'providerIcon',
            header: '',
            cell: ({ row }) => (
                <div
                    className={`rounded-md p-1 ${!enabledConnectors.includes(row.original.id) && !enableAllConnectors ? 'dark:bg-gray-350' : 'dark:bg-white'}`}
                >
                    {row.original.icon ? (
                        <img
                            src={row.original.icon}
                            alt={row.original.name}
                            className={`w-14 h-14 ${!enabledConnectors.includes(row.original.id) && !enableAllConnectors ? 'opacity-40 grayscale' : ''}`}
                        />
                    ) : (
                        row.original.iconComponent && (
                            <row.original.iconComponent className={'h-14 w-14'} />
                        )
                    )}
                </div>
            ),
        },
        {
            header: 'Name',
            accessorKey: 'name',
            cell: ({ row }) => (
                <div className={'flex flex-col max-w-96'}>
                    <h3 className={'text-lg'}>{row.original.name}</h3>
                    <span className={'text-xs text-accent-foreground line-clamp-2'}>
                        {row.original.description}
                    </span>
                </div>
            ),
        },
        {
            accessorKey: 'classification',
            header: 'Category',
        },
    ]

    return (
        <div>
            {bannerVisible && (
                <PageBanner
                    onButtonClick={onBannerButtonClick}
                    title={'Chat with your documents'}
                    icon={
                        <img
                            src={DataConnectorBanner}
                            alt={'data-connector'}
                            className={'h-[208px] w-[302px]'}
                        />
                    }
                />
            )}
            <div className={'px-6 h-[100%]'}>
                <Tabs className={'flex flex-col gap-5'} defaultValue={'list'}>
                    <div className={'my-6 px-[15%]'}>
                        <MegaSearch
                            onSearch={setSearch}
                            placeholder={'Search by Connector Name or Category'}
                        />
                    </div>
                    <div className={'flex justify-end'}>
                        <div>
                            <TabsList>
                                <TabsTrigger value={'list'}>
                                    <List size={18} />
                                </TabsTrigger>
                                <TabsTrigger value="cards">
                                    <LayoutGrid size={18} />
                                </TabsTrigger>
                            </TabsList>
                        </div>
                    </div>
                    <TabsContent value={'cards'}>
                        <div className={'grid gap-4 grid-cols-3 xl:grid-cols-4'}>
                            {filteredConnectors
                                .filter((c) => enabledConnectors.includes(c.id))
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((connector) => (
                                    <ConnectorCard
                                        key={connector.id}
                                        connector={connector}
                                        onCardClick={() => onCardClick(connector.id)}
                                    />
                                ))}
                            {filteredConnectors
                                .filter((c) => !enabledConnectors.includes(c.id))
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((connector) => (
                                    <ConnectorCard
                                        key={connector.id}
                                        connector={connector}
                                        onCardClick={() => onCardClick(connector.id)}
                                        isDisabled={!enableAllConnectors}
                                    />
                                ))}
                        </div>
                    </TabsContent>
                    <TabsContent value={'list'}>
                        <DefaultDataTable
                            columns={columns}
                            onRowClick={(row) => onCardClick(row.original.id)}
                            data={[
                                ...filteredConnectors
                                    .filter((c) => enabledConnectors.includes(c.id))
                                    .sort((a, b) => a.name.localeCompare(b.name)),
                                ...filteredConnectors
                                    .filter((c) => !enabledConnectors.includes(c.id))
                                    .sort((a, b) => a.name.localeCompare(b.name)),
                            ]}
                        />
                    </TabsContent>
                </Tabs>
                <Dialog open={addToProjectOpen} onOpenChange={setAddToProjectOpen}>
                    <DialogContent>
                        <SelectProjectForm onSelectProjectSubmit={onAddToProject} />
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}
