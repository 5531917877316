import type React from 'react'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'
import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getIngestionFeed, type IngestionFeedEventItem } from '@/api/ingestion.ts'
import type { ColumnDef } from '@tanstack/react-table'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import IngestionStatusBadge from '@/components/home/ingestion/ingestionStatusBadge.tsx'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import millify from 'millify'
import { FeedDataTable } from '@/components/home/feed/feedDataTable/FeedDataTable.tsx'
import { formatCurrency, formatDate, getTimezone, formatDuration } from '@/lib/utils.ts'
import { Check, X } from 'lucide-react'
import FeedHeader from '@/components/pagination/FeedHeader'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx'

export interface IngestionTableProps {
    queryParams: paginationAndSortingParams
}

export const IngestionTable: React.FC<IngestionTableProps> = ({ queryParams }) => {
    const ingestionEventQuery = useQuery({
        queryKey: [QueryKeys.INGESTION_FEED_ALL, queryParams],
        queryFn: () =>
            getIngestionFeed(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const columns: ColumnDef<IngestionFeedEventItem>[] = [
        {
            accessorKey: 'createdAt',
            header: `Timestamp (GMT${getTimezone()})`,
            cell: ({ row }) => <p>{formatDate(row.original.createdAt)}</p>,
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            enableSorting: false,
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={16}
                                width={16}
                            />
                        ) : (
                            <AiriaIcon className="w-4 h-4" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            accessorKey: 'dataSourceName',
            header: 'Data Source',
        },
        {
            accessorKey: 'state',
            header: 'Status',
            cell: ({ row }) => <IngestionStatusBadge status={row.original.state} />,
        },
        {
            header: 'Embedding Provider',
            accessorKey: 'embeddings.provider',
            cell: ({ row }) => (
                <div className={'flex gap-1 items-center'}>
                    <ProviderIcon provider={row.original.embeddings.provider} className="w-4 h-4" />
                    {row.original.embeddings.provider}
                </div>
            ),
        },
        {
            header: 'Vector DB Provider',
            accessorKey: 'vectorDatabase.provider',
        },
        {
            header: 'Vector DB Region',
            accessorKey: 'vectorDatabase.region',
            cell: ({ row }) => (
                <span>
                    {row.original.vectorDatabase.region} {row.original.vectorDatabase.cloud}
                </span>
            ),
        },
        {
            header: 'Total Files',
            accessorKey: 'filesProcessed.total',
            cell: ({ row }) => (
                <div className={'flex items-center gap-2 bg-card rounded-xs py-1 px-2'}>
                    {row.original.filesProcessed.total}
                    <div className={'flex items-center gap-1 text-xs'}>
                        {row.original.filesProcessed.successful > 0 && (
                            <span
                                className={
                                    'flex items-center gap-1 rounded-xs bg-background px-1 text-success'
                                }
                            >
                                <Check className={'h-3 w-3'} />
                                {row.original.filesProcessed.successful}
                            </span>
                        )}
                        {row.original.filesProcessed.failed > 0 && (
                            <span
                                className={
                                    'flex items-center gap-1 rounded-xs bg-background px-1 text-error'
                                }
                            >
                                <X className={'h-3 w-3'} />
                                {row.original.filesProcessed.failed}
                            </span>
                        )}
                    </div>
                </div>
            ),
        },
        {
            header: 'Ingested Data',
            accessorKey: 'ingestedDataSize.sizeInMb',
            cell: ({ row }) => {
                const totalSizeInBytes = row.original.ingestedDataSize.sizeInMb * 1_048_576
                return (
                    <span className={'text-foreground'}>
                        {millify(totalSizeInBytes, {
                            units: ['B', 'KB', 'MB', 'GB', 'TB'],
                            space: true,
                        })}
                    </span>
                )
            },
        },
        {
            header: 'Duration',
            accessorKey: 'ingestDuration',
            cell: ({ row }) => {
                if (row.original.ingestDuration && row.original.ingestDuration !== '') {
                    const parts = row.original.ingestDuration.split(':')
                    const hours = Number.parseInt(parts[0], 10)
                    const minutes = Number.parseInt(parts[1], 10)
                    const seconds = Number.parseFloat(parts[2])
                    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000
                    const duration = millify(totalMilliseconds, {
                        precision: 2,
                        units: ['ms', 's', 'm', 'h'],
                    })

                    return <p className="text-gray-550]">{duration}</p>
                }
            },
        },
        {
            accessorKey: 'dataSourceType',
            header: 'Connector Type',
        },
        {
            accessorKey: 'ingestDuration',
            header: 'Ingestion Time',
            cell: ({ row }) => {
                const duration = formatDuration(row.original.ingestDuration)
                return duration ? <p className="text-gray-550">{duration}</p> : null
            },
        },
        {
            accessorKey: 'embeddings.tokenConsumed',
            header: 'Embeddings Consumption',
            cell: ({ row }) => (
                <Tooltip>
                    <TooltipTrigger>
                        <span>{row.original.embeddings.tokensConsumed}</span>
                        <TooltipContent>
                            <div className={'flex flex-col'}>
                                <span>Total Cost: </span>
                                <span>
                                    {formatCurrency(row.original.embeddings.totalEmbeddingsCost)}
                                </span>
                            </div>
                        </TooltipContent>
                    </TooltipTrigger>
                </Tooltip>
            ),
        },
    ]

    return (
        <TooltipProvider>
            <FeedDataTable
                totalCount={ingestionEventQuery.data?.totalCount ?? 0}
                data={ingestionEventQuery.data?.items ?? []}
                columns={columns}
                Header={
                    <FeedHeader
                        type="ingestion"
                        headerTitle="Data Ingestion"
                        queryParams={queryParams}
                        refreshQueryKeys={[QueryKeys.INGESTION_FEED_ALL, queryParams]}
                        totalCount={ingestionEventQuery.data?.totalCount ?? 0}
                        additionalFilteringParameters={[
                            {
                                column: 'Status',
                                internalColumn: 'state',
                                value: [],
                                multiple: true,
                            },
                            {
                                column: 'Database Type',
                                internalColumn: 'databaseType',
                                value: [],
                                multiple: false,
                            },
                        ]}
                        additionalPrepopulatedValues={[
                            {
                                column: 'Status',
                                values: [
                                    'Unknown',
                                    'Pending',
                                    'Validating',
                                    'Ingesting',
                                    'Storing',
                                    'Indexing',
                                    'Finished',
                                    'Failed',
                                    'Validated',
                                    'Ingested',
                                    'Stored',
                                    'Indexed',
                                ],
                            },
                            { column: 'Database Type', values: ['Pinecone', 'MongoDB'] },
                        ]}
                    />
                }
            />
        </TooltipProvider>
    )
}
