import apiRequest from '@/lib/apiClient'
import type { LibraryPrompt } from './prompts'

export interface LibraryModel {
    credentialsId: string
    id: string
    category: string
    description: string
    downloads: number
    type: string
    languages: string[]
    licenseType?: string
    name: string
    price: string
    inputTokenPrice: number
    outputTokenPrice: number
    promptId: string
    url: string
    provider: string
    rating: number
    tags: string[]
    available: boolean
    allowAiriaCredentials: boolean
    tokenPriceConversion: TokenPriceConversion
}

export interface TokenPriceConversion {
    inputTokenPrice1K: number
    outputTokenPrice1K: number
}

export interface LibaryTool {
    id: string
    name: string
    standardizedName: string
    description: string
    methodType: string
    purpose: string
    useCases: LibraryToolUseCase[]
    apiEndpoint: string
    headers: LibraryToolHeader[]
    parameters: LibraryToolParameter[]
    createdAt: number
    provider: string
    tags: string[]
    authRequired: boolean
}

export interface LibraryToolUseCase {
    id: string
    description: string
    examples: LibraryToolExample[]
    toolDefinitionId: string
}

export interface LibraryToolExample {
    id: string
    toolParameterId: string
    value: string
    enabled: boolean
}

export interface LibraryToolHeader {
    id: string
    key: string
    value: string
    toolDefinitionId: string
}

export interface LibraryToolParameter {
    id: string
    name: string
    type: string
    description: string
    default?: string
    validOptions: string[]
    toolDefinitionId: string
}

export const LIBRARY_PROVIDERS = ['Ollama', 'OpenAI', 'Anthropic']
export const LIBRARY_LANGUAGES = ['English', 'Spanish', 'French', 'Chinese', 'German']
export const LIBRARY_CHIP_FILTERS = ['Multimodal', 'NLP', 'Airia Key Enabled']

export const LIBRARY_MODELS: LibraryModel[] = [
    {
        credentialsId: '00000000-0000-0000-0000-000000000000',
        id: '577d4dd1-b798-434d-8cec-5a62698500dd',
        category: 'Multimodal',
        description:
            'GPT-4o mini is our most cost-efficient small model that’s smarter and cheaper than GPT-3.5 Turbo, and has vision capabilities. The model has 128K context and an October 2023 knowledge cutoff.',
        downloads: 0,
        type: 'Text',
        languages: ['English'],
        name: 'gpt-4o-mini',
        price: 'Pricing from OpenAI models available at: https://openai.com/api/pricing/',
        inputTokenPrice: 0.00000015,
        outputTokenPrice: 0.0000006,
        promptId: '00000000-0000-0000-0000-000000000000',
        url: 'https://platform.openai.com/docs/models/gpt-4o-mini',
        provider: 'OpenAI',
        rating: 0,
        tags: ['chat', 'gpt', '4o', 'mini', 'openai'],
        available: true,
        tokenPriceConversion: {
            inputTokenPrice1K: 0.00015,
            outputTokenPrice1K: 0.0006,
        },
        allowAiriaCredentials: true,
    },
    {
        credentialsId: '00000000-0000-0000-0000-000000000000',
        id: '5a3055c5-095b-4c91-89cf-063616297718',
        category: 'Multimodal',
        description:
            'GPT-4o is our most advanced multimodal model that’s faster and cheaper than GPT-4 Turbo with stronger vision capabilities. The model has 128K context and an October 2023 knowledge cutoff.',
        downloads: 0,
        type: 'Text',
        languages: ['English'],
        name: 'gpt-4o',
        price: 'Pricing from OpenAI models available at: https://openai.com/api/pricing/',
        inputTokenPrice: 0.000005,
        outputTokenPrice: 0.000001,
        promptId: '00000000-0000-0000-0000-000000000000',
        url: 'https://platform.openai.com/docs/models/gpt-4o',
        provider: 'OpenAI',
        rating: 0,
        tags: ['chat', 'gpt', '4o', 'openai'],
        available: true,
        tokenPriceConversion: {
            inputTokenPrice1K: 0.005,
            outputTokenPrice1K: 0.001,
        },
        allowAiriaCredentials: true,
    },
]

export const LIBRARY_PROMPTS: LibraryPrompt[] = [
    {
        recommendedModels: ['Opus'],
        tags: [''],
        id: '0baea60e-490c-4624-a23e-774da4865cc9',
        name: 'Ethical Dilemma Navigator',
        industry: 'General',
        activeVersion: 1,
        latestVersion: 1,
        description:
            'Help the user think through complex ethical dilemmas and provide different perspectives.',
        recommendedProvider: 'Anthropic',
        modifiedOn: '1727740800000',
        prompt: 'When assisting users with complex ethical dilemmas, guide them through a comprehensive analysis by identifying core ethical principles, introducing relevant ethical frameworks, and evaluating potential consequences of various choices. Acknowledge the inherent complexities and nuances of the situation, encouraging users to reflect on their personal values and biases. Maintain an objective, non-judgmental tone throughout the process, emphasizing critical thinking, empathy, and responsible decision-making. Prompt users to consider multiple perspectives and the potential impact on all stakeholders involved. Encourage thoughtful introspection while avoiding prescriptive solutions. Conclude by offering additional resources for further exploration of ethical reasoning, aiming to equip users with the tools to navigate complex moral issues effectively rather than providing definitive answers.',
    },
]

export const MODEL_DESCRIPTION = `
### Model Description
IMAGE TO TEXT TRANSLATE is a transformers model pretrained on a large corpus of English data in a self-supervised fashion. This means it was pretrained on the raw texts only, with no humans labelling them in any way (which is why it can use lots of publicly available data) with an automatic process to generate
## Training Procedure
### Preprocessing
The texts are lowercased and tokenized using WordPiece and a vocabulary size of 30,000. The inputs of the model are then of the form:
\`\`\`python
python -m torch.distributed.launch --nproc_per_node=8 ./examples/question-answering/run_qa.py \\
    --model_name_or_path bert-large-uncased-whole-word-masking \\
    --dataset_name squad \\
    --do_train \\
    --do_eval \\
    --learning_rate 3e-5 \\
    --num_train_epochs 2 \\
    --max_seq_length 384 \\
    --doc_stride 128 \\
    --output_dir ./examples/models/wwm_uncased_finetuned_squad/ \\
    --per_device_eval_batch_size=3   \\
    --per_device_train_batch_size=3   \\
\`\`\`
IMAGE TO TEXT TRANSLATE is a transformers model pretrained on a large corpus of English data in a self-supervised fashion. This means it was pretrained on the raw texts only, with no humans labelling them in any way (which is why it can use lots of publicly available data) with an automatic process to generate...
`

export const getAllLibraryModels = async (availableOnly: boolean): Promise<LibraryModel[]> => {
    try {
        const res = await apiRequest<{ models: LibraryModel[] }>(
            '/api/marketplace/v1/Library/models'
        )
        if (availableOnly) {
            return res.models.filter((model) => model.available)
        }
        return res.models
    } catch {
        return LIBRARY_MODELS
    }
}

export const getAllLibraryPrompts = async (): Promise<LibraryPrompt[]> => {
    try {
        const res = await apiRequest<{ prompts: LibraryPrompt[] }>(
            '/api/marketplace/v1/Library/prompts'
        )
        return res.prompts
    } catch {
        return LIBRARY_PROMPTS
    }
}

export const getAllLibraryTools = async () => {
    try {
        const res = await apiRequest<{ tools: LibaryTool[] }>('/api/marketplace/v1/Library/tools')
        return res.tools
    } catch (err) {
        console.log('error fetching tools', err)
    }
}
