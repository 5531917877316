import { type AiOperationsExecution, getAllExecutions } from '@/api/aiOperationsExecution.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import type React from 'react'
import { useEffect, useState } from 'react'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { Link } from '@tanstack/react-router'
import { Copy } from 'lucide-react'
import { toast } from '@/components/ui/use-toast.ts'
import { formatCurrency, formatDate, getTimezone } from '@/lib/utils.ts'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import { FeedDataTable } from '@/components/home/feed/feedDataTable/FeedDataTable.tsx'
import FeedHeader, { type paginationAndSortingParams } from '@/components/pagination/FeedHeader'
import { MODEL_PROVIDERS } from '@/api/credentials'

interface ConsumptionProps {
    queryParams: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
        credentialsTypes?: string
    }
}

const Consumption: React.FC<ConsumptionProps> = ({ queryParams }) => {
    const [executionOperations, setExecutionOperations] = useState<AiOperationsExecution[]>([])
    const operationsQuery = useQuery({
        queryKey: [QueryKeys.ALL_OPERATIONS_EXECUTIONS, queryParams],
        queryFn: () => getAllExecutions(queryParams),
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    useEffect(() => {
        if (operationsQuery.data) {
            setExecutionOperations(operationsQuery.data.items)
        }
    }, [operationsQuery.data])

    const onCopyIconClick = (executionId: string) => {
        navigator.clipboard.writeText(executionId)
        toast({
            title: 'Copied!',
            description: 'Execution ID copied to clipboard.',
        })
    }

    const columns: ColumnDef<AiOperationsExecution>[] = [
        {
            accessorKey: 'executionDateTime',
            header: `Timestamp (GMT${getTimezone()})`,
            cell: ({ row }) => <p>{formatDate(row.original.executionDateTime)}</p>,
        },
        {
            accessorKey: 'providerType',
            header: 'Model Provider',
            cell: ({ row }) => (
                <div className={'flex gap-1 items-center'}>
                    <ProviderIcon provider={row.original.providerType} className="w-4 h-4" />
                    {row.original.providerType}
                </div>
            ),
        },
        {
            accessorKey: 'modelName',
            header: 'Model Name',
            cell: ({ row }) => (
                <span
                    className={
                        'flex items-center whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                    }
                >
                    {row.original.modelName}
                </span>
            ),
        },
        {
            accessorKey: 'credentialsType',
            header: 'Credential Type',
            cell: ({ row }) => <span>{row.original.credentialsType}</span>,
        },
        {
            accessorKey: 'executionSourceType',
            header: 'Source',
        },
        {
            accessorKey: 'pipelineId',
            header: 'Pipeline',
            cell: ({ row }) => {
                const isEmptyGuid =
                    row.original.pipelineId === '00000000-0000-0000-0000-000000000000'
                return (
                    <Link
                        to={'/$projectId/pipelines/$pipelineId/$versionNumber'}
                        params={{
                            projectId: row.original.projectId,
                            pipelineId: row.original.pipelineId,
                            versionNumber: row.original.pipelineVersion,
                        }}
                        className={
                            'flex items-center text-primary hover:underline whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                        }
                    >
                        {isEmptyGuid
                            ? ''
                            : row.original.pipelineName !== ''
                              ? row.original.pipelineName
                              : row.original.pipelineId}
                    </Link>
                )
            },
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            header: 'Total Spend',
            accessorKey: 'totalTokenAmountConsumed',
            cell: ({ row }) => {
                return <span>{`$${row.original.totalTokenAmountConsumed}`}</span>
            },
        },
        {
            accessorKey: 'inputTokenAmountConsumed',
            header: 'Input Spend',
            cell: ({ row }) => {
                return <span>{`$${row.original.inputTokenAmountConsumed}`}</span>
            },
        },
        {
            accessorKey: 'outputTokenAmountConsumed',
            header: 'Output Spend',
            cell: ({ row }) => {
                return <span>{`$${row.original.outputTokenAmountConsumed}`}</span>
            },
        },
        {
            header: 'Total Tokens',
            accessorKey: 'totalTokenCountConsumed',
        },
        {
            accessorKey: 'inputTokenCountConsumed',
            header: 'Input Tokens',
        },
        {
            accessorKey: 'outputTokenCountConsumed',
            header: 'Output Tokens',
        },
        {
            accessorKey: 'balanceUsed',
            header: 'Balance Used',
            cell: ({ row }) => {
                const displayedBalance = +row.original.balanceUsed
                return (
                    <span>
                        {isNaN(displayedBalance)
                            ? `$${row.original.balanceUsed}`
                            : formatCurrency(displayedBalance)}
                    </span>
                )
            },
        },
        {
            accessorKey: 'executionId',
            header: 'Execution ID',
            cell: ({ row }) => (
                <div className={'flex'}>
                    <div
                        className={'hover:text-accent-foreground cursor-pointer'}
                        onClick={() => onCopyIconClick(row.original.executionId)}
                    >
                        <Copy className={'h-4 w-4'} />
                    </div>
                </div>
            ),
        },
    ]

    return (
        <>
            <FeedDataTable
                columns={columns}
                data={executionOperations}
                totalCount={operationsQuery.data?.totalCount ?? 0}
                Header={
                    <FeedHeader
                        type="consumption"
                        headerTitle="Token Consumption"
                        queryParams={queryParams}
                        refreshQueryKeys={[QueryKeys.ALL_OPERATIONS_EXECUTIONS, queryParams]}
                        totalCount={operationsQuery.data?.totalCount ?? 0}
                        additionalFilteringParameters={[
                            {
                                column: 'Model Provider',
                                internalColumn: 'providerType',
                                value: [],
                                multiple: true,
                            },
                            {
                                column: 'Source',
                                internalColumn: 'executionSourceTypes',
                                value: [],
                                multiple: true,
                            },
                            {
                                column: 'Credential Type',
                                internalColumn: 'credentialsTypes',
                                value: [],
                            },
                        ]}
                        additionalPrepopulatedValues={[
                            { column: 'Model Provider', values: MODEL_PROVIDERS },
                            {
                                column: 'Source',
                                values: ['Pipeline', 'Gateway', 'Datasource', 'Playground'],
                            },
                            {
                                column: 'Credential Type',
                                values: ['Airia', 'Custom'],
                            },
                        ]}
                    />
                }
            />
        </>
    )
}

export default Consumption
